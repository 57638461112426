import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactFullpage from '@fullpage/react-fullpage';
import { useCheckEN } from '../hooks/index';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';

import Slider from '../components/slider';
import RichText from '../components/richtext';
import LoadingDesktop from '../components/loadingDesktop';
import LoadingMobile from '../components/loading';
import Footer from '../components/footer';
import HoverImage from '../components/hoverimage';

import EmojiSurprise from '../static/assets/emoji-surprised.svg';
import EmojiSmile from '../static/assets/emoji-smile.svg';
import Sternchen from '../static/assets/sternchen.svg';
import StarBrackets from '../static/assets/star-in-brackets.svg';
import Arrow from '../static/assets/arrow.svg';
import Circle from '../static/assets/circle.svg';
import CircleStarCircle from '../static/assets/circle-star-circle.svg';
import SquareCircle from '../static/assets/square-circle.svg';
import SquareCircleBlack from '../static/assets/square-circle-black.svg';
import BracketOpen from '../static/assets/bracket-open.svg';
import BracketClose from '../static/assets/bracket-close.svg';
import HolDesign from '../static/assets/hol-design.svg';
import { useWindowSize } from '../hooks/useWindowSize';

const IndexPage = ({ data }) => {
  const [url] = useCheckEN();

  /**
   * QUERIES
   */
  const header = data.header.edges.map(edge => edge.node);
  const { _rawSubtitle } = header[0];
  const sectionOne = data.sectionOne.edges.map(edge => edge.node);
  const projects = sectionOne[0].images.map(image => image);
  const sectionTwo = data.sectionTwo.edges.map(edge => edge.node);
  const {
    welcome,
    welcome2,
    _rawHeadline,
    _rawHeadline2,
    title,
    _rawDescription,
    _rawDescription2,
  } = sectionTwo[0];
  const sectionThree = data.sectionThree.edges.map(edge => edge.node);
  const sectionFour = data.sectionFour.edges.map(edge => edge.node);
  const sectionFive = data.sectionFive.edges.map(edge => edge.node);
  const sectionSix = data.sectionSix.edges.map(edge => edge.node);

  const size = useWindowSize();
  const isMobile = size.width < 1020;
  const isDesktop = size.width > 1020;
  const [useMobile, setUseMobile] = useState(false);
  const [useDesktop, setUseDesktop] = useState(false);

  useEffect(() => {
    setUseMobile(isMobile);
    setUseDesktop(isDesktop);
  }, [isDesktop, isMobile]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  const [activeSection, setActiveSection] = useState(0);
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  return (
    <>
      {useDesktop && <LoadingDesktop />}
      {useMobile && <LoadingMobile />}
      {!loading && (
        <>
          <Layout
            subtitle={_rawSubtitle}
            current={
              <>
                <Arrow style={{ height: '15px', marginRight: '-5px' }} />
                <Arrow style={{ height: '15px', marginRight: '-5px' }} />
                <Arrow style={{ height: '15px', marginRight: '-5px' }} />
                <Arrow style={{ height: '15px', marginRight: '-5px' }} />
                <Arrow style={{ height: '15px', marginRight: '-5px' }} />
                <Arrow style={{ height: '15px' }} />
              </>
            }
            dePath="/"
            enPath="/en"
            hideHeader={activeSection !== 0}
            hideNav={activeSection === 6}
            disableUp={activeSection === 0}
            disableDown={activeSection === 5}
            onClickUp={() => window.fullpage_api.moveSectionUp()}
            onClickDown={() => window.fullpage_api.moveSectionDown()}
          >
            <SEO />
            <ReactFullpage
              licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
              responsiveWidth={1020}
              scrollOverflow
              autoScrolling
              scrollingSpeed={600}
              easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
              afterLoad={afterLoad}
              render={() => (
                <div id="fullpage-wrapper">
                  <StyledSectionOne className="section light fp-auto-height-responsive">
                    <div className="inner">
                      <>
                        <Slider content={projects} />
                        <div className="bg-gradient invisible"></div>
                        <div className="headline centered big invisible">
                          On
                          <br />
                          brand
                          <br />
                          with
                          <br />
                          the
                          <div className="star">
                            <StarBrackets />
                          </div>
                          right
                          <br />
                          kind
                          <br />
                          of
                          <br />
                          <span>
                            boo
                            <EmojiSurprise className="emoji" />
                            om
                          </span>
                        </div>
                      </>
                    </div>
                  </StyledSectionOne>
                  <StyledSectionTwo className="section dark fp-auto-height-responsive">
                    <div className="inner">
                      <p className="welcome-left">{welcome}</p>
                      <p className="welcome-right right-aligned">{welcome2}</p>
                      <h3 className="headline centered big">
                        <RichText blocks={_rawHeadline} />
                      </h3>
                      <div className="headline-second centered big">
                        <RichText blocks={_rawHeadline2} />
                        <span>
                          <BracketOpen
                            className="emoji"
                            style={{ fill: 'var(--bg-color)' }}
                          />
                          BO
                          <EmojiSmile
                            className="emoji"
                            style={{ fill: 'var(--bg-color)' }}
                          />
                          <SquareCircle
                            className="emoji"
                            style={{ fill: 'var(--bg-color)' }}
                          />
                          O
                          <SquareCircle
                            className="emoji"
                            style={{ fill: 'var(--bg-color)' }}
                          />
                          M
                          <BracketClose
                            className="emoji"
                            style={{ fill: 'var(--bg-color)' }}
                          />
                        </span>
                      </div>
                      <h3 className="overline-left right-aligned">{title}</h3>
                      <div className="copy-left right-aligned">
                        <RichText blocks={_rawDescription} />
                      </div>
                      <p className="overline-right">
                        <CircleStarCircle /> <br />
                        <CircleStarCircle />
                      </p>
                      <div className="copy-right">
                        <RichText blocks={_rawDescription2} />
                      </div>
                    </div>
                  </StyledSectionTwo>
                  <StyledSectionThree className="section light fp-auto-height-responsive">
                    <div className="inner">
                      <div className="headline-left big right-aligned">
                        <RichText blocks={sectionThree[0]._rawHeadline} />
                      </div>
                      <div className="headline-right big">
                        <RichText blocks={sectionThree[0]._rawHeadline2} />
                      </div>
                      <div className="emoji-smile">
                        <EmojiSmile />
                      </div>
                      <h4 className="desc-left">
                        <RichText blocks={sectionThree[0]._rawDescription} />
                      </h4>
                      <div className="overline-left right-aligned">
                        <RichText blocks={sectionThree[0]._rawOverlineJap} />
                      </div>
                      <h2 className="overline-right">
                        <RichText blocks={sectionThree[0]._rawOverline} />
                      </h2>
                      <h2 className="overline-right-second">
                        <RichText blocks={sectionThree[0]._rawOverline2} />
                      </h2>
                      <div className="subline-left right-aligned">
                        <RichText blocks={sectionThree[0]._rawSubtitle} />
                      </div>
                      <div className="subline-right">
                        <RichText blocks={sectionThree[0]._rawSubtitle2} />
                      </div>
                    </div>
                  </StyledSectionThree>
                  <StyledSectionFour className="section light fp-auto-height-responsive">
                    <div className="inner">
                      <div className="centered big headline">
                        <RichText blocks={sectionFour[0]._rawTitle} />
                      </div>
                      <HoverImage
                        className="img-1"
                        img={sectionFour[0].project1.image.image.asset.fluid}
                        desc={sectionFour[0].project1._rawTitle}
                        slug={sectionFour[0].project1.slug}
                      />
                      <HoverImage
                        className="img-2"
                        img={sectionFour[0].project2.image.image.asset.fluid}
                        desc={sectionFour[0].project2._rawTitle}
                        slug={sectionFour[0].project2.slug}
                      />
                      <HoverImage
                        className="img-3"
                        img={sectionFour[0].project3.image.image.asset.fluid}
                        desc={sectionFour[0].project3._rawTitle}
                        slug={sectionFour[0].project3.slug}
                      />
                      <HoverImage
                        className="img-4"
                        img={sectionFour[0].project4.image.image.asset.fluid}
                        desc={sectionFour[0].project4._rawTitle}
                        slug={sectionFour[0].project4.slug}
                      />
                      <HoverImage
                        className="img-5"
                        img={sectionFour[0].project5.image.image.asset.fluid}
                        desc={sectionFour[0].project5._rawTitle}
                        slug={sectionFour[0].project5.slug}
                      />
                      <HoverImage
                        className="img-6"
                        img={sectionFour[0].project6.image.image.asset.fluid}
                        desc={sectionFour[0].project6._rawTitle}
                        slug={sectionFour[0].project6.slug}
                      />
                      <HoverImage
                        className="img-7"
                        img={sectionFour[0].project7.image.image.asset.fluid}
                        desc={sectionFour[0].project7._rawTitle}
                        slug={sectionFour[0].project7.slug}
                      />
                      <HoverImage
                        className="img-8"
                        img={sectionFour[0].project8.image.image.asset.fluid}
                        desc={sectionFour[0].project8._rawTitle}
                        slug={sectionFour[0].project8.slug}
                      />
                      <p className="centered big subline">
                        see <br /> mo
                        <Sternchen className="emoji" />
                        <span>
                          <SquareCircleBlack className="emoji" />
                        </span>
                        re <br />
                        <Link to={!url ? '/design' : '/en/design'}>
                          <span style={{ textDecoration: 'underline' }}>
                            here
                          </span>
                        </Link>
                      </p>
                    </div>
                  </StyledSectionFour>
                  <StyledSectionFive className="section dark fp-auto-height-responsive">
                    <div className="inner">
                      <div className="headline centered big">
                        <RichText blocks={sectionFive[0]._rawTitle} />
                      </div>
                      <div className="headline-left right-aligned big">
                        <RichText blocks={sectionFive[0]._rawText1} />
                        <CircleStarCircle />
                        <RichText blocks={sectionFive[0]._rawText3} />
                        <span style={{ paddingRight: 'var(--spacing-M)' }}>
                          <Circle />
                          <Circle />
                          <Circle />
                        </span>
                        <CircleStarCircle />
                        <br />
                        <span className="second-circlestar">
                          <CircleStarCircle />
                        </span>
                        <RichText blocks={sectionFive[0]._rawText5} />
                        <RichText blocks={sectionFive[0]._rawText6} />
                      </div>
                      <div className="headline-right big">
                        <RichText blocks={sectionFive[0]._rawText2} />
                        <RichText blocks={sectionFive[0]._rawText4} />
                        <RichText blocks={sectionFive[0]._rawText7} />
                      </div>
                      <div className="arrows">
                        <div style={{ paddingBottom: 'var(--spacing-S)' }}>
                          <Arrow className="emoji" />
                        </div>
                        <div style={{ paddingBottom: 'var(--spacing-L)' }}>
                          <Arrow className="emoji" />
                          <Arrow className="emoji" />
                        </div>
                        <div style={{ paddingBottom: 'var(--spacing-S)' }}>
                          <Arrow className="emoji" />
                        </div>
                        <div style={{ paddingBottom: 'var(--spacing-M)' }}>
                          <Arrow className="emoji" />
                          <Arrow className="emoji" />
                          <Arrow className="emoji" />
                        </div>
                        <div>
                          <Arrow className="emoji" />
                          <Arrow className="emoji" />
                        </div>
                      </div>
                    </div>
                  </StyledSectionFive>
                  <StyledSectionSix className="section light fp-auto-height-responsive">
                    <div className="inner">
                      <div className="centered big headline">
                        <RichText blocks={sectionSix[0]._rawTitle} />
                      </div>
                      <div className="centered text-1">
                        <RichText blocks={sectionSix[0]._rawText1} />
                      </div>
                      <div className="left right-aligned">
                        <div className="spacing">
                          <RichText blocks={sectionSix[0]._rawText2} />
                        </div>
                        <div>
                          <RichText blocks={sectionSix[0]._rawText3} />
                        </div>
                      </div>
                      <div className="emoji-design">
                        <HolDesign />
                      </div>
                      <div className="right">
                        <div className="spacing">
                          <RichText blocks={sectionSix[0]._rawText4} />
                        </div>
                        <div className="spacing">
                          <RichText blocks={sectionSix[0]._rawText5} />
                        </div>
                      </div>
                      <div className="centered desc">
                        <RichText blocks={sectionSix[0]._rawDescription} />
                      </div>
                    </div>
                  </StyledSectionSix>
                  <Footer />
                </div>
              )}
            />
          </Layout>
        </>
      )}
    </>
  );
};

const StyledSectionOne = styled.section`
  .inner {
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-template-rows: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .headline {
      grid-column: 1 / calc(var(--grid-cols) + 1);
      grid-row: 1 / calc(var(--grid-cols) + 1);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
    }

    .star {
      grid-column: 1 / calc(var(--grid-cols) + 1);
      grid-row: 1 / calc(var(--grid-cols) + 1);
      padding: calc(var(--fontsize-1) * 1) 0;
      svg {
        width: calc(var(--fontsize-1) * 1.5);
      }
    }

    .bg-gradient {
      z-index: 1;
      grid-column: 1 / calc(var(--grid-cols) + 1);
      grid-row: 1 / calc(var(--grid-cols) + 1);
      height: 100%;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.85) 45%,
        rgba(255, 255, 255, 0.85) 55%,
        rgba(255, 255, 255, 0) 80%
      );
    }
  }
`;

const StyledSectionTwo = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-template-rows: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .headline {
      grid-column: 1 / 13;
      grid-row: 1 / 4;
    }

    .headline-second {
      grid-column: 1 / 13;
      grid-row: 5 / 10;
    }

    .welcome-left {
      grid-column: 4;
      grid-row: 1;
    }

    .welcome-right {
      white-space: nowrap;
      grid-column: 9;
      grid-row: 1;
      font-family: var(--font-3);
    }

    .overline-left {
      grid-column: 1 / 4;
      grid-row: 4;
    }

    .copy-left {
      grid-column: 1 / 4;
      grid-row: 6 / 11;
    }

    .overline-right {
      grid-column: 10 / 13;
      grid-row: 4;
    }

    .copy-right {
      grid-column: 10 / 13;
      grid-row: 6 / 11;
    }

    @media (max-width: 1020px) {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-M) var(--spacing-XXS);

      .welcome-left,
      .welcome-right,
      .overline-right {
        display: none;
      }

      .overline-left {
        padding: var(--spacing-XS) 0;
      }
    }
  }
`;

const StyledSectionThree = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-template-rows: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .desc-left {
      grid-column: 1 / 5;
      grid-row: 2 / 4;
    }

    .overline-left {
      font-family: var(--font-3);
      grid-column: 1 / 6;
      grid-row: 4 / 5;
    }

    .overline-right {
      grid-column: 8 / 12;
      grid-row: 2 / 4;
    }

    .overline-right-second {
      grid-column: 11 / 13;
      grid-row: 2 / 4;
    }

    .headline-left {
      grid-column: 1 / 6;
      grid-row: 5 / 7;
    }

    .emoji-smile {
      display: flex;
      justify-content: center;
      grid-column: 6 / 8;
      grid-row: 6 / 9;
    }

    .subline-left {
      grid-column: 1 / 6;
      grid-row: 8 / 11;
    }

    .headline-right {
      grid-column: 8 / 12;
      grid-row: 5 / 7;
    }

    .subline-right {
      grid-column: 8 / 12;
      grid-row: 8 / 11;
    }

    .mobile-content {
      display: none;
    }

    @media (max-width: 1020px) {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-M) var(--spacing-XXS);

      .headline-left,
      .headline-right {
        text-align: center;
      }

      .subline-left,
      .subline-right {
        display: none;
      }

      .overline-right,
      .overline-right-second {
        text-align: right;
      }

      .emoji-smile {
        padding: var(--spacing-XS) 0;
        svg {
          width: 100%;
        }
      }
    }
  }
`;

const StyledSectionFour = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-XXS);

    .headline {
      grid-column: 2;
    }

    .subline {
      grid-column: 2;
      transform: translateY(-250px);
    }

    .img-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .img {
      height: calc(100vw / 3);
    }

    .img-1,
    .img-4,
    .img-7 {
      grid-column: 1;
    }

    .img-2,
    .img-5 {
      grid-column: 2;
      transform: translateY(100px);
    }

    .img-3,
    .img-6,
    .img-8 {
      grid-column: 3;
    }

    .img-desc {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .gradient {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.85) 45%,
        rgba(255, 255, 255, 0.85) 55%,
        rgba(255, 255, 255, 0) 90%
      );
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-M) 0 var(--spacing-M) var(--spacing-XXS);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--spacing-XXS);

      .headline {
        grid-column: 1 / 4;
        padding-bottom: var(--spacing-S);
      }

      .subline {
        grid-column: 1 / 3;
        transform: translateY(0);
        padding-top: var(--spacing-S);
      }

      .img-1,
      .img-3,
      .img-5,
      .img-7 {
        grid-column: 1;
      }

      .img-2,
      .img-4,
      .img-6,
      .img-8 {
        grid-column: 2;
      }

      .img-2,
      .img-5 {
        transform: translateY(0);
      }
    }
  }
`;

const StyledSectionFive = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-template-rows: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .headline {
      grid-column: 1 / 13;
      grid-row: 1 / 3;
    }

    .headline-left {
      grid-column: 1 / 6;
      grid-row: 4 / 13;
      p {
        padding-bottom: var(--spacing-S);
      }
    }

    .headline-right {
      grid-column: 8 / 12;
      grid-row: 4 / 13;
      p {
        padding-bottom: var(--spacing-S);
      }
    }

    .emoji {
      fill: white;
    }

    .second-circlestar {
      display: inline-block;
      transform: translateY(-10px);
    }

    .arrows {
      display: flex;
      flex-direction: column;
      grid-column: 6 / 8;
      grid-row: 4 / 13;

      div {
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 1020px) {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-M) var(--spacing-XXS);

      .headline {
        padding-bottom: var(--spacing-S);
      }

      .headline-left,
      .headline-right {
        text-align: center;
      }

      .arrows {
        display: none;
      }

      .second-circlestar {
        display: inline;
      }
    }
  }
`;

const StyledSectionSix = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    margin-bottom: var(--spacing-M);
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-template-rows: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .headline {
      grid-column: 1 / 13;
      grid-row: 1 / 4;
    }

    .text-1 {
      grid-column: 1 / 13;
      grid-row: 5 / 7;
    }

    .spacing {
      padding-bottom: var(--spacing-S);
    }

    .left {
      grid-column: 1 / 5;
      grid-row: 8 / 13;
    }

    .right {
      grid-column: 9 / 12;
      grid-row: 8 / 13;
    }

    .desc {
      grid-column: 5 / 9;
      grid-row: 13;
    }

    .emoji-design {
      grid-column: 6 / 8;
      grid-row: 6 / 13;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        min-width: 250%;
      }
    }

    @media (max-width: 1020px) {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-M) var(--spacing-XXS);

      .text-1 {
        padding: var(--spacing-S) 0;
      }

      .emoji-design {
        svg {
          min-width: 100%;
        }
      }

      .left,
      .right {
        text-align: center;
      }
    }
  }
`;

export const query = graphql`
  query Homepage {
    header: allSanityHeader {
      edges {
        node {
          title
          _rawSubtitle
        }
      }
    }
    sectionOne: allSanityHomepageSectionOne {
      edges {
        node {
          images {
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    sectionTwo: allSanityHomepageSectionTwo {
      edges {
        node {
          welcome
          welcome2
          title
          _rawHeadline
          _rawHeadline2
          _rawDescription
          _rawDescription2
        }
      }
    }
    sectionThree: allSanityHomepageSectionThree {
      edges {
        node {
          _rawDescription
          _rawOverlineJap
          _rawOverline
          _rawOverline2
          _rawHeadline
          _rawHeadline2
          _rawSubtitle
          _rawSubtitle2
        }
      }
    }
    sectionFour: allSanityHomepageSectionFour {
      edges {
        node {
          _rawTitle
          project1 {
            slug
            _rawTitle
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project2 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project3 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project4 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project5 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project6 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project7 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          project8 {
            _rawTitle
            slug
            image {
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    sectionFive: allSanityHomepageSectionFive {
      edges {
        node {
          _rawTitle
          _rawText1
          _rawText2
          _rawText3
          _rawText4
          _rawText5
          _rawText6
          _rawText7
        }
      }
    }
    sectionSix: allSanityHomepageSectionSix {
      edges {
        node {
          _rawTitle
          _rawText1
          _rawText2
          _rawText3
          _rawText4
          _rawText5
          _rawDescription
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default localize(IndexPage);
