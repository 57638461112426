import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const Slider = ({ content, onClick }) => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    mode: 'free-snap',
    spacing: 10,
    loop: true,
    duration: 7000,
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <StyledSlider>
      <div ref={sliderRef} className="keen-slider">
        {content.map((item, i) => (
          <div key={i} className="keen-slider__slide" onClick={onClick}>
            <Img fluid={item.image.asset.fluid} alt={item.alt} />
          </div>
        ))}
      </div>
      <StyledGradient></StyledGradient>
    </StyledSlider>
  );
};

const StyledSlider = styled.div`
  position: relative;
  grid-column: 1 / calc(var(--grid-cols) + 1);
  grid-row: 1 / calc(var(--grid-cols) + 1);
  height: 100vh;
  display: flex;
  flex-direction: columm;
  align-items: center;

  .keen-slider {
    width: 100vw;
    height: auto;

    .keen-slider__slide {
      min-width: 33vw;
      height: 33vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

const StyledGradient = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  height: var(--spacing-XXL);
  width: 100vw;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (max-width: 1020px) {
    display: none;
  }
`;

Slider.propTypes = {
  content: PropTypes.array,
  onClick: PropTypes.func,
};

export default Slider;
