import React, { useEffect } from 'react';
import { useClientSide } from '../hooks/index';

const LoadingDesktop = () => {
  const client = useClientSide();

  useEffect(() => {
    if (client) {
      // eslint-disable-next-line global-require
      const mojs = require('@mojs/core');
      class Stern extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path d="M45.68,60.4L17.76,88.33l-6.09-6.09L39.6,54.31H0v-8.62h39.6L11.67,17.77l6.09-6.09L45.68,39.6V0h8.63v39.6l27.91-27.91 l6.09,6.09L60.4,45.69H100v8.62H60.4l27.91,27.93l-6.09,6.09L54.31,60.4V100h-8.63V60.4z"/>';
        }
      }
      mojs.addShape('stern', Stern);

      class Acht extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path xmlns="http://www.w3.org/2000/svg" d="M95.4,67.5c-2.6,4.9-6.1,8.5-10.5,11.1c-4.4,2.5-9.4,3.8-15.1,3.8c-6.2,0-11.5-1.5-15.9-4.5s-7.7-7.3-9.8-12.9    c-2,4.2-4.8,7.5-8.3,9.8c-3.6,2.4-7.7,3.6-12.3,3.6c-4.3,0-8.2-1.2-11.7-3.6C8.4,72.4,5.6,69,3.5,64.8c-2.1-4.3-3.1-9.1-3.1-14.4    s1-10.2,3.1-14.5s4.9-7.7,8.4-10.1s7.4-3.6,11.7-3.6c4.7,0,8.8,1.2,12.3,3.6s6.3,5.8,8.4,10.1c2.1-5.7,5.4-10.1,9.8-13.1    s9.7-4.5,15.9-4.5c5.5,0,10.5,1.3,15,3.9s8,6.3,10.6,11.1c2.6,4.9,3.9,10.6,3.9,17.1C99.2,56.9,97.9,62.6,95.4,67.5z M83.3,35.4    C79.7,31.8,75,30,69.2,30s-10.5,1.8-14.1,5.4s-5.4,8.5-5.4,14.8c0,6.4,1.8,11.3,5.3,14.8c3.5,3.5,8.2,5.3,14.1,5.3    c5.8,0,10.5-1.8,14.1-5.3c3.6-3.5,5.4-8.5,5.4-14.8C88.8,43.9,87,39,83.3,35.4z M35.1,37.6c-2.9-3-6.7-4.6-11.3-4.6    c-4,0-7.4,1.5-10.1,4.6c-2.8,3-4.1,7.2-4.1,12.6s1.4,9.6,4.1,12.6c2.7,3,6.1,4.5,10.2,4.5c4.6,0,8.3-1.5,11.3-4.5    c3-3,4.4-7.2,4.4-12.6C39.6,44.9,38.1,40.7,35.1,37.6z"/>';
        }
      }
      mojs.addShape('acht', Acht);

      class Rahmen extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path xmlns="http://www.w3.org/2000/svg" d="M100-0.4L0-0.4l0,100.8l100,0L100-0.4z M91.9,74.5C87.6,82.1,81.7,88,74.3,92.4C66.9,96.8,58.8,99,50,99  c-8.8,0-16.9-2.2-24.4-6.5C18.2,88,12.3,82.1,7.9,74.5C3.6,67,1.4,58.8,1.4,50s2.2-17,6.5-24.5C12.3,18,18.2,12.1,25.6,7.7  C33.1,3.4,41.2,1.2,50,1.2s16.9,2.2,24.3,6.5c7.4,4.4,13.3,10.3,17.6,17.8c4.3,7.5,6.5,15.6,6.5,24.5C98.4,58.8,96.3,67,91.9,74.5z"/>';
        }
      }
      mojs.addShape('rahmen', Rahmen);

      class K extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path xmlns="http://www.w3.org/2000/svg" d="M100,9.9v16.3L52.3,67.4l11,10.7H100v12H0v-12h47.4L0,31.6V16.1l43.7,42.7L100,9.9z"/>';
        }
      }
      mojs.addShape('k', K);

      class R extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path xmlns="http://www.w3.org/2000/svg" d="M100,11.7v12.8l-23.7,6.8c-4.1,1.2-7.4,2.8-10,4.6c-2.6,1.8-4.5,4.4-5.9,7.9c-1.4,3.5-2.1,8.2-2.1,14v18.4H100v12H0.2V46.5   c0-6.4,1.3-12,3.9-16.9c2.6-4.9,6.1-8.6,10.6-11.3c4.4-2.6,9.2-3.9,14.5-3.9c6.7,0,12.1,2,16.5,6.1c4.3,4,7.2,9.7,8.5,17   c1.5-5.3,4.1-9.3,7.6-11.9c3.6-2.6,8.8-5,15.6-7.1L100,11.7z M47.5,47.2c0-6.4-1.7-11.4-5.2-15c-3.5-3.7-7.8-5.5-13-5.5   c-5.2,0-9.6,1.8-13,5.4c-3.5,3.6-5.2,8.6-5.2,15v29.2h36.5V47.2z"/>';
        }
      }
      mojs.addShape('r', R);

      const initLoadingAnim = () => {
        const burst = new mojs.Burst({
          radius: { 140: 240 },
          count: 8,
          angle: { '0': 120 },
          children: {
            shape: 'cross',
            stroke: 'black',
            strokeWidth: { 2: 0 },
            angle: { 0: 0 },
            radius: { 11: 19 },
            duration: 5000,
          },
        });

        const burst2 = new mojs.Burst({
          radius: { 115: 195 },
          count: 8,
          angle: { '90': 90 },

          children: {
            shape: 'stern',
            points: 7,
            degree: 90,
            stroke: 'none',
            fill: 'black',
            strokeWidth: { 2: 0 },
            angle: { '0': 0 },
            radius: { 14: 19 },
            opacity: { 1: 1 },
            duration: 5000,
          },
        });

        const burst3 = new mojs.Burst({
          radius: { 115: 195 },
          count: 8,
          angle: { '20': 20 },

          children: {
            shape: 'rahmen',
            points: 7,

            stroke: 'none',
            fill: 'black',
            strokeWidth: { 2: 0 },
            angle: { '0': 0 },
            radius: { 14: 19 },
            opacity: { 1: 1 },
            duration: 5000,
          },
        });

        const burst4 = new mojs.Burst({
          radius: { 85: 155 },
          count: 10,
          angle: { '80': 0 },
          children: {
            shape: 'acht',
            stroke: 'none',
            fill: 'black',
            strokeWidth: { 2: 0 },
            angle: { '0': 0 },
            radius: { 7: 44 },
            opacity: { 1: 1 },
            duration: 5000,
          },
        });

        const burst5 = new mojs.Burst({
          radius: { 20: 95 },
          count: 5,
          angle: { '0': 80 },
          children: {
            shape: 'k',
            stroke: 'none',
            fill: 'black',
            strokeWidth: { 2: 0 },
            angle: { '0': 0 },
            radius: { 11: 44 },
            opacity: { 1: 1 },
            duration: 5000,
          },
        });

        const burst6 = new mojs.Burst({
          radius: { 50: 125 },
          count: 8,
          angle: { '0': 80 },
          children: {
            shape: 'r',
            stroke: 'none',
            fill: 'black',
            strokeWidth: { 2: 0 },
            angle: { '0': 0 },
            radius: { 11: 44 },
            opacity: { 1: 1 },
            duration: 5000,
          },
        });

        new mojs.Timeline({
          // repeat: 1,
          repeat: 0,
        })
          .add(burst, burst2, burst3, burst4, burst5, burst6)
          .play();
      };

      initLoadingAnim();
    }
  }, [client]);

  return <div></div>;
};

export default LoadingDesktop;
