import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useCheckEN } from '../hooks';

import RichText from './richtext';

const HoverImage = ({ index, img, desc, className, slug }) => {
  const [hover, setHover] = React.useState();
  const [url] = useCheckEN();

  return (
    <StyledHoverImage
      className={className}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={index}
    >
      <Link to={url ? `/en/design/${slug}` : `/design/${slug}`}>
        <Img fluid={img} />
        {hover && (
          <>
            <div className="gradient"></div>
            <div className="centered big img-desc">
              <RichText blocks={desc} />
            </div>
          </>
        )}
      </Link>
    </StyledHoverImage>
  );
};

const StyledHoverImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .img-desc {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .gradient {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.85) 45%,
      rgba(255, 255, 255, 0.85) 55%,
      rgba(255, 255, 255, 0) 90%
    );
  }
`;

HoverImage.propTypes = {
  index: PropTypes.number,
  img: PropTypes.object,
  desc: PropTypes.array,
  className: PropTypes.string,
  slug: PropTypes.string,
};

export default HoverImage;
